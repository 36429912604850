<script>
import Swal from 'sweetalert2'

import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";


/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);
        const doesCorrectOpen = ref(false);

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
          doesCorrectOpen,
        };
    },
    page: {
    title: "Informations",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "informations",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Informations",
          active: true,
        },
      ],
      errors: [],
      forests: [],
      information: {},
      informations: [],
      forest_id: "",
      loading: false,
      status: 201,
      STORAGE_URL: Helper.STORAGE_URL,
    };
  },
  methods: {
    loadList() {
      fetch(Helper.route('ctaf/informations/photo'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.informations = data.data
      })
    },
    addItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('type', "photo")
      formData.append('forest_id', this.forest_id)
      formData.append('file', document.getElementById("file").files[0])
      fetch(Helper.route('ctaf/informations'), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.name = ""
          this.doesAddOpen = false
          this.informations.push(data.data)
          Swal.fire("Photo ajouter !", "Le Photo a été enregistrée avec succès.", "success");
        } else {      
            this.errors.forest_id = Helper.getError(data.data, "forest_id")
            this.errors.file = Helper.getError(data.data, "file")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    editItem() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('_method', "PUT")
      formData.append('type', "pta")
      formData.append('forest_id', this.forest_id)
      formData.append('file', document.getElementById("file1").files[0])
      fetch(Helper.route('ctaf/informations/'+this.information.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.doesCorrectOpen = false
          this.informations = this.informations.map(i => i.id !== this.information.id ? i : data.data)
          Swal.fire("Photo modifier !", "La Photo a été modifiée avec succès.", "success");
        } else {      
            this.errors.forest_id = Helper.getError(data.data, "forest_id")
            this.errors.file = Helper.getError(data.data, "file")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    deleteIt() {
      this.loading = true
      fetch(Helper.route('ctaf/informations/'+this.information.id), Helper.requestOptions("DELETE"))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.text()
      })
      .then(data => {
        console.log(data);
        this.name = ""
        this.loading = false
        this.doesDeleteOpen = false
        if (this.status == 204) {
            this.informations = this.informations.filter(i => i.id !== this.information.id)
            Swal.fire("Photo supprimer !", "La Photo a été supprimée avec succès.", "success");
        } else {
            Swal.fire("Photo non supprimer ?", "La suppression n'a pas marché, veuillez réessayez plus tard.", "error");
        }
      })
    },
    loadForests() {
      fetch(Helper.route('ctaf/forests'), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        this.forests = data.data
      })
    },
    initItem(information, action="edit") {
        this.information = information
        this.initData(information)
        if (action == "delete") {
          this.doesDeleteOpen = true
        } else if(action == "edit") {
          this.doesCorrectOpen = true
        } else {
          this.doesEditOpen = true
        }
    },
    initData(item = null) {
      this.forest_id = item != null ? item.forest_id : ""
      this.name = item != null ? item.title : ""
    }
  },
  mounted() {
    this.loadList()
    this.loadForests()
  },
  watch: {
    doesAddOpen: function() {
      this.errors = []
      this.initData(null)
    },
    doesEditOpen: function() {
      this.errors = []
      if (!this.doesEditOpen) {
        this.information = null
        this.initData(null)
      }
    },
    doesDeleteOpen: function() {
      if (!this.doesDeleteOpen) {
        this.information = null
        this.initData(null)
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="hstack mb-3">
        <b-button variant="primary" @click="doesAddOpen = true" class="ms-auto">
            <i class="bx bx-plus font-size-16 align-middle me-2"></i>
            Ajouter un nouveau
        </b-button>
    </div>
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Listes des Photos</h4>
            <p class="card-title-desc">
              Le tableau ci-dessous présente la listes des <code>Photos</code>.
            </p>

            <div class="row g-3">
              <div class="col-lg-4 col-md-6" v-for="(information) in informations" :key="information.id">
                <div class="bg-light shadow h-100">
                  <a :href="STORAGE_URL+information.file" target="_blank">
                    <img :src="STORAGE_URL+information.file" class="w-100" style="height: 180px; object-fit: cover;" alt="">
                  </a>
                  <div class="p-3 pt-0">
                    <div class="mt-3">
                      Forêt : <router-link :to="'/ctaf/forests/'+information.forest.id">{{ information.forest.name }}</router-link>
                    </div>
                    <div class="mt-3">
                      Statut :
                      {{ (information.status == 1) ? "Approuvé" : ""  }}
                      {{ information.status == 0 ? "En étude" : "" }}
                      {{ information.status == -1 ? "Réjétée" : "" }}
                    </div>
                    <div class="hstack mt-3">
                        <b-button v-if="information.status != 1" variant="danger" @click="initItem(information, 'delete')" class="btn-sm">
                            Supprimer <i class="bx bx-trash font-size-12 align-middle mx-1"></i>
                        </b-button>
                        <template v-if="information.status == -1">
                          <div class="hstack">
                            <b-button variant="warning" @click="initItem(information, 'view')" class="ms-2 btn-sm">
                                Message de rejet
                            </b-button>
                            <b-button variant="primary" @click="initItem(information, 'edit')" class="ms-2 btn-sm">
                                Corrigé
                            </b-button>
                          </div>
                        </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- ADD MODAL -->
    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        size="md"
        centered
        title="Ajouter une Photo"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="addItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <select required v-model="forest_id" class="form-control">
                    <option value="">Selectionnez la forêt</option>
                    <option v-for="forest in forests" :key="forest.id" :value="forest.id">{{ forest.name }}</option>
                </select>
            </div>
            <div class="col-md-12 mb-3">
                <label for="">Photo (Image)</label>
                <input type="file" required id="file" accept=".png, .jpeg, .jpg" class="form-control">
                <span class="text-danger mt-2" v-if="errors.name">{{ errors.file }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>

    <!-- CORRECT MODAL -->
    <b-modal
        v-model="doesCorrectOpen"
        id="modal-center"
        size="md"
        centered
        title="Corriger la photo"
        title-class="font-18"
        hide-footer
        >
        <form @submit.prevent="editItem()">
          <div class="row">
            <div class="col-md-12 mb-3">
                <select required v-model="forest_id" class="form-control">
                    <option value="">Selectionnez la forêt</option>
                    <option v-for="forest in forests" :key="forest.id" :value="forest.id">{{ forest.name }}</option>
                </select>
            </div>
            <div class="col-md-12 mb-3">
                <label for="">Photo (Image)</label>
                <input type="file" required id="file1" accept=".png, .jpeg, .jpg" class="form-control">
                <span class="text-danger mt-2" v-if="errors.name">{{ errors.file }}</span>
            </div>
          </div>
          <div class="mt-3" v-if="!loading">
            <input type="submit" value="Enregistrer" class="btn btn-primary">
          </div>
          <div class="mt-3 text-center" v-else>
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </form>
    </b-modal>

    <!-- DELETE MODAL -->
    <b-modal
        v-model="doesDeleteOpen"
        id="modal-center"
        title="Supprimer une Photo"
        title-class="font-18"
        hide-footer
        >
        <p class="mb-2">
            Voulez-vous vraiment supprimer cette photo ?
        </p>
        <div class="hstack mt-2" v-if="!loading">
          <button class="btn btn-dark ms-auto" @click="doesDeleteOpen = false">Annuler</button>
          <button class="ms-3 btn btn-danger" @click="deleteIt()">Supprimer</button>
        </div>
        <div class="text-center" v-else>
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
    </b-modal>

    <b-modal
        v-model="doesEditOpen"
        id="modal-center"
        title="Message de rejet"
        title-class="font-18"
        hide-footer
        centered
        >
        <p class="mb-2 lh-lg">
            {{ information?.message }}
        </p>
    </b-modal>


  </Layout>
</template>
